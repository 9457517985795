export const DocsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.96875 24H19.0312C20.1944 24 21.1406 23.0538 21.1406 21.8906V7.03125H16.2188C15.0556 7.03125 14.1094 6.08503 14.1094 4.92188V0H4.96875C3.80559 0 2.85938 0.946219 2.85938 2.10938V21.8906C2.85938 23.0538 3.80559 24 4.96875 24ZM7.78125 9.89062H16.2188C16.6074 9.89062 16.9219 10.2051 16.9219 10.5938C16.9219 10.9824 16.6074 11.2969 16.2188 11.2969H7.78125C7.39261 11.2969 7.07812 10.9824 7.07812 10.5938C7.07812 10.2051 7.39261 9.89062 7.78125 9.89062ZM7.78125 12.7031H16.2188C16.6074 12.7031 16.9219 13.0176 16.9219 13.4062C16.9219 13.7949 16.6074 14.1094 16.2188 14.1094H7.78125C7.39261 14.1094 7.07812 13.7949 7.07812 13.4062C7.07812 13.0176 7.39261 12.7031 7.78125 12.7031ZM7.78125 15.5156H16.2188C16.6074 15.5156 16.9219 15.8301 16.9219 16.2188C16.9219 16.6074 16.6074 16.9219 16.2188 16.9219H7.78125C7.39261 16.9219 7.07812 16.6074 7.07812 16.2188C7.07812 15.8301 7.39261 15.5156 7.78125 15.5156ZM7.78125 18.3281H13.4062C13.7949 18.3281 14.1094 18.6426 14.1094 19.0312C14.1094 19.4199 13.7949 19.7344 13.4062 19.7344H7.78125C7.39261 19.7344 7.07812 19.4199 7.07812 19.0312C7.07812 18.6426 7.39261 18.3281 7.78125 18.3281Z'
        fill='white'
      />
      <path
        d='M16.2188 5.625H20.7286L15.5156 0.411987V4.92188C15.5156 5.30982 15.8308 5.625 16.2188 5.625Z'
        fill='white'
      />
    </svg>
  );
};
