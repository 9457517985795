import React from 'react';

export const SearchIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.8045 14.862L11.2545 10.312C12.1359 9.22335 12.6665 7.84002 12.6665 6.33338C12.6665 2.84141 9.82522 9.15527e-05 6.33325 9.15527e-05C2.84128 9.15527e-05 0 2.84138 0 6.33335C0 9.82531 2.84132 12.6666 6.33328 12.6666C7.83992 12.6666 9.22325 12.136 10.3119 11.2546L14.8619 15.8046C14.9919 15.9346 15.1625 15.9999 15.3332 15.9999C15.5039 15.9999 15.6745 15.9346 15.8045 15.8046C16.0652 15.5439 16.0652 15.1226 15.8045 14.862ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33335C1.33333 3.57603 3.57597 1.33339 6.33328 1.33339C9.0906 1.33339 11.3332 3.57603 11.3332 6.33335C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z'
      fill='#9AA1B0'
    />
  </svg>
);
