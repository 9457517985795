export const PadlockEmpty = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.125 22H6.875C5.84167 22 5 21.1592 5 20.125V11.375C5 10.3408 5.84167 9.5 6.875 9.5H18.125C19.1583 9.5 20 10.3408 20 11.375V20.125C20 21.1592 19.1583 22 18.125 22ZM6.875 10.75C6.53083 10.75 6.25 11.03 6.25 11.375V20.125C6.25 20.47 6.53083 20.75 6.875 20.75H18.125C18.4692 20.75 18.75 20.47 18.75 20.125V11.375C18.75 11.03 18.4692 10.75 18.125 10.75H6.875Z'
        fill='#1E1E20'
      />
      <path
        d='M16.875 10.75C16.53 10.75 16.25 10.47 16.25 10.125V7C16.25 4.9325 14.5675 3.25 12.5 3.25C10.4325 3.25 8.75 4.9325 8.75 7V10.125C8.75 10.47 8.47 10.75 8.125 10.75C7.78 10.75 7.5 10.47 7.5 10.125V7C7.5 4.2425 9.7425 2 12.5 2C15.2575 2 17.5 4.2425 17.5 7V10.125C17.5 10.47 17.22 10.75 16.875 10.75Z'
        fill='#1E1E20'
      />
      <path
        d='M12.5002 16.1667C11.581 16.1667 10.8335 15.4192 10.8335 14.5C10.8335 13.5808 11.581 12.8333 12.5002 12.8333C13.4193 12.8333 14.1668 13.5808 14.1668 14.5C14.1668 15.4192 13.4193 16.1667 12.5002 16.1667ZM12.5002 14.0833C12.271 14.0833 12.0835 14.27 12.0835 14.5C12.0835 14.73 12.271 14.9167 12.5002 14.9167C12.7293 14.9167 12.9168 14.73 12.9168 14.5C12.9168 14.27 12.7293 14.0833 12.5002 14.0833Z'
        fill='#1E1E20'
      />
      <path
        d='M12.5 18.6667C12.155 18.6667 11.875 18.3867 11.875 18.0417V15.75C11.875 15.405 12.155 15.125 12.5 15.125C12.845 15.125 13.125 15.405 13.125 15.75V18.0417C13.125 18.3867 12.845 18.6667 12.5 18.6667Z'
        fill='#1E1E20'
      />
    </svg>
  );
};
