import React from 'react';

export const MenuIcno = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_178_422)'>
        <path
          d='M1.70215 6.9175H26.2982C27.2368 6.9175 28 6.15429 28 5.21576C28 4.27722 27.2368 3.51361 26.2983 3.51361H1.70215C0.763613 3.51361 0 4.27722 0 5.21576C0 6.15429 0.763613 6.9175 1.70215 6.9175Z'
          fill='#1E1E20'
        />
        <path
          d='M26.2983 12.2977H1.70215C0.763613 12.2977 0 13.0613 0 13.9999C0 14.9384 0.763613 15.7016 1.70215 15.7016H26.2982C27.2368 15.7016 28 14.9384 28 13.9999C28 13.0613 27.2368 12.2977 26.2983 12.2977Z'
          fill='#1E1E20'
        />
        <path
          d='M26.2983 21.082H1.70215C0.763613 21.082 0 21.846 0 22.7845C0 23.7231 0.763613 24.4863 1.70215 24.4863H26.2982C27.2368 24.4863 28 23.7231 28 22.7845C28 21.846 27.2368 21.082 26.2983 21.082Z'
          fill='#1E1E20'
        />
      </g>
      <defs>
        <clipPath id='clip0_178_422'>
          <rect width='28' height='28' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
