export const Plus = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2002_14375)'>
        <path
          d='M18.2498 9.08335H10.9166V1.74998C10.9166 1.24409 10.5059 0.833374 9.99983 0.833374C9.49395 0.833374 9.08323 1.24409 9.08323 1.74998V9.08335H1.74986C1.24397 9.08335 0.833252 9.49407 0.833252 9.99996C0.833252 10.506 1.24397 10.9167 1.74986 10.9167H9.08323V18.2499C9.08323 18.756 9.49395 19.1667 9.99983 19.1667C10.5059 19.1667 10.9166 18.756 10.9166 18.2499V10.9167H18.2498C18.7559 10.9167 19.1666 10.506 19.1666 9.99996C19.1666 9.49407 18.7559 9.08335 18.2498 9.08335Z'
          fill='#9AA1B0'
        />
      </g>
      <defs>
        <clipPath id='clip0_2002_14375'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
