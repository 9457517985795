import React from 'react';

export const Back = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3338 9.16681H3.67881L8.08964 4.75598C8.16923 4.67911 8.23271 4.58715 8.27639 4.48548C8.32006 4.38381 8.34305 4.27447 8.34401 4.16382C8.34497 4.05317 8.32389 3.94343 8.28199 3.84102C8.24009 3.73861 8.17821 3.64556 8.09997 3.56732C8.02172 3.48908 7.92868 3.4272 7.82626 3.3853C7.72385 3.3434 7.61412 3.32231 7.50347 3.32327C7.39282 3.32424 7.28347 3.34722 7.1818 3.3909C7.08013 3.43457 6.98818 3.49806 6.9113 3.57765L1.07797 9.41098C0.921747 9.56725 0.833984 9.77917 0.833984 10.0001C0.833984 10.2211 0.921747 10.433 1.07797 10.5893L6.9113 16.4226C7.06847 16.5744 7.27897 16.6584 7.49747 16.6565C7.71597 16.6546 7.92498 16.567 8.07948 16.4125C8.23399 16.258 8.32163 16.049 8.32353 15.8305C8.32543 15.612 8.24143 15.4015 8.08964 15.2443L3.67881 10.8335H18.3338C18.5548 10.8335 18.7668 10.7457 18.9231 10.5894C19.0793 10.4331 19.1671 10.2212 19.1671 10.0001C19.1671 9.77913 19.0793 9.56717 18.9231 9.41089C18.7668 9.25461 18.5548 9.16681 18.3338 9.16681Z'
        fill='#9AA1B0'
      />
    </svg>
  );
};
