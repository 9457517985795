export const Duplicate = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.14773 21H13.125C13.6943 20.9991 14.2401 20.7726 14.6427 20.37C15.0453 19.9674 15.2719 19.4216 15.2727 18.8523V8.42043C15.2719 7.85109 15.0453 7.30531 14.6427 6.90272C14.2401 6.50013 13.6943 6.27357 13.125 6.27271H5.14773C4.57838 6.27357 4.0326 6.50013 3.63001 6.90272C3.22742 7.30531 3.00087 7.85109 3 8.42043V18.8523C3.00087 19.4216 3.22742 19.9674 3.63001 20.37C4.0326 20.7726 4.57838 20.9991 5.14773 21V21ZM4.63636 8.42043C4.63636 8.28481 4.69024 8.15474 4.78614 8.05884C4.88204 7.96294 5.01211 7.90907 5.14773 7.90907H13.125C13.2606 7.90907 13.3907 7.96294 13.4866 8.05884C13.5825 8.15474 13.6364 8.28481 13.6364 8.42043V18.8523C13.6364 18.9879 13.5825 19.1179 13.4866 19.2138C13.3907 19.3097 13.2606 19.3636 13.125 19.3636H5.14773C5.01211 19.3636 4.88204 19.3097 4.78614 19.2138C4.69024 19.1179 4.63636 18.9879 4.63636 18.8523V8.42043Z'
      fill='#59647A'
    />
    <path
      d='M18.8482 3H10.8791C10.456 3.0004 10.0423 3.12559 9.69001 3.35989C9.33768 3.5942 9.06228 3.92722 8.89827 4.31727C8.81364 4.51713 8.81187 4.74241 8.89335 4.94357C8.97483 5.14473 9.13287 5.30528 9.33273 5.38991C9.53258 5.47454 9.75787 5.47631 9.95902 5.39483C10.1602 5.31336 10.3207 5.15531 10.4054 4.95545C10.4441 4.86149 10.5097 4.78107 10.594 4.72429C10.6783 4.66752 10.7775 4.63693 10.8791 4.63636H18.8482C18.9848 4.63658 19.1158 4.69096 19.2124 4.78758C19.309 4.8842 19.3634 5.01518 19.3636 5.15182V15.5755C19.3634 15.7121 19.309 15.8431 19.2124 15.9397C19.1158 16.0363 18.9848 16.0907 18.8482 16.0909H16.9091C16.6921 16.0909 16.484 16.1771 16.3305 16.3305C16.1771 16.484 16.0909 16.6921 16.0909 16.9091C16.0909 17.1261 16.1771 17.3342 16.3305 17.4876C16.484 17.6411 16.6921 17.7273 16.9091 17.7273H18.8482C19.4187 17.7266 19.9656 17.4997 20.369 17.0963C20.7724 16.6929 20.9993 16.146 21 15.5755V5.15182C20.9993 4.58132 20.7724 4.03437 20.369 3.63097C19.9656 3.22757 19.4187 3.00065 18.8482 3V3Z'
      fill='#59647A'
    />
  </svg>
);
