import React from 'react';

type TProps = { color?: string };

export const Padlock = ({ color }: TProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.9999 9.24979H17.3332V6.49979C17.3332 3.46654 14.9412 0.999794 11.9999 0.999794C9.05856 0.999794 6.66656 3.46654 6.66656 6.49979V9.24979H5.99989C4.89767 9.24979 3.99989 10.1747 3.99989 11.3123V20.9373C3.99989 22.0749 4.89767 22.9998 5.99989 22.9998H17.9999C19.1021 22.9998 19.9999 22.0749 19.9999 20.9373V11.3123C19.9999 10.1747 19.1021 9.24979 17.9999 9.24979ZM8.44434 6.49979C8.44434 4.47763 10.039 2.83313 11.9999 2.83313C13.9608 2.83313 15.5554 4.47763 15.5554 6.49979V9.24979H8.44434V6.49979ZM12.8888 16.3283V18.4165C12.8888 18.9225 12.4914 19.3331 11.9999 19.3331C11.5083 19.3331 11.111 18.9225 11.111 18.4165V16.3283C10.5821 16.0102 10.2221 15.4254 10.2221 14.7498C10.2221 13.7387 11.0194 12.9165 11.9999 12.9165C12.9803 12.9165 13.7777 13.7387 13.7777 14.7498C13.7777 15.4254 13.4177 16.0102 12.8888 16.3283Z'
        fill='#9AA1B0'
      />
    </svg>
  );
};
