export const Delete = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.6608 5.66125L16.4476 19.4858H7.55356L6.34064 5.66125L4.80371 5.79598L6.03878 19.8712C6.10378 20.5199 6.66669 21.0286 7.32061 21.0286H16.6806C17.3343 21.0286 17.8974 20.5202 17.9635 19.8619L19.1978 5.79598L17.6608 5.66125Z'
      fill='#59647A'
    />
    <path
      d='M14.8276 2H9.17049C8.46153 2 7.88477 2.57677 7.88477 3.28573V5.72859H9.4276V3.54284H14.5704V5.72855H16.1133V3.28569C16.1133 2.57677 15.5366 2 14.8276 2Z'
      fill='#59647A'
    />
    <path
      d='M20.2285 4.95715H3.77144C3.34534 4.95715 3 5.30249 3 5.72859C3 6.15469 3.34534 6.50003 3.77144 6.50003H20.2286C20.6547 6.50003 21 6.15469 21 5.72859C21 5.30249 20.6546 4.95715 20.2285 4.95715Z'
      fill='#59647A'
    />
  </svg>
);
