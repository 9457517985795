import React from 'react';

export const Down = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9998 5.70571C14.0037 5.56814 13.9523 5.43305 13.8526 5.31922C13.753 5.20538 13.61 5.11846 13.4437 5.07054C13.2774 5.02261 13.096 5.01607 12.9247 5.05181C12.7534 5.08755 12.6008 5.16381 12.4879 5.26998L8.0138 9.32087L3.54126 5.26998C3.47167 5.19654 3.38332 5.13503 3.28167 5.08931C3.18001 5.0436 3.06725 5.01465 2.95051 5.00429C2.83376 4.99393 2.71554 5.00238 2.6032 5.02911C2.49086 5.05583 2.38681 5.10027 2.29764 5.15962C2.20846 5.21897 2.13612 5.29197 2.085 5.37403C2.03388 5.4561 2.00517 5.54547 2.00064 5.63654C1.99611 5.72762 2.0158 5.81844 2.05862 5.90332C2.10144 5.98819 2.16638 6.0653 2.2494 6.12981L7.36536 10.7677C7.44549 10.8405 7.54495 10.899 7.65704 10.9391C7.76914 10.9792 7.89114 11 8.01459 11C8.13803 11 8.26004 10.9792 8.37213 10.9391C8.48422 10.899 8.58378 10.8405 8.66391 10.7677L13.7848 6.12981C13.919 6.01265 13.9952 5.86237 14 5.70571L13.9998 5.70571Z'
        fill='#1E1E20'
      />
    </svg>
  );
};
