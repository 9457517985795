import React from 'react';

export const Up = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.00021 10.2943C1.99631 10.4319 2.04772 10.5669 2.14738 10.6808C2.24704 10.7946 2.39002 10.8815 2.55631 10.9295C2.7226 10.9774 2.90399 10.9839 3.0753 10.9482C3.24661 10.9124 3.39923 10.8362 3.51209 10.73L7.9862 6.67913L12.4587 10.73C12.5283 10.8035 12.6167 10.865 12.7183 10.9107C12.82 10.9564 12.9327 10.9853 13.0495 10.9957C13.1662 11.0061 13.2845 10.9976 13.3968 10.9709C13.5091 10.9442 13.6132 10.8997 13.7024 10.8404C13.7915 10.781 13.8639 10.708 13.915 10.626C13.9661 10.5439 13.9948 10.4545 13.9994 10.3635C14.0039 10.2724 13.9842 10.1816 13.9414 10.0967C13.8986 10.0118 13.8336 9.9347 13.7506 9.87019L8.63464 5.23229C8.55451 5.15946 8.45505 5.10098 8.34296 5.06087C8.23086 5.02077 8.10886 5 7.98541 5C7.86197 5 7.73996 5.02077 7.62787 5.06087C7.51578 5.10098 7.41622 5.15946 7.33609 5.23229L2.21521 9.87019C2.08104 9.98735 2.00476 10.1376 2.00001 10.2943L2.00021 10.2943Z'
        fill='#1E1E20'
      />
    </svg>
  );
};
