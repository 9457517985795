import React from 'react';

export const Plus = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.8999 10.9H13.1V2.09993C13.1 1.49286 12.6072 1 11.9999 1C11.3928 1 10.9 1.49286 10.9 2.09993V10.9H2.09993C1.49286 10.9 1 11.3928 1 11.9999C1 12.6072 1.49286 13.1 2.09993 13.1H10.9V21.8999C10.9 22.5071 11.3928 23 11.9999 23C12.6072 23 13.1 22.5071 13.1 21.8999V13.1H21.8999C22.5071 13.1 23 12.6072 23 11.9999C23 11.3928 22.5071 10.9 21.8999 10.9Z'
        fill='white'
      />
    </svg>
  );
};
