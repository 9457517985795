import React from 'react';

export const Cross = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.46586 7.99994L15.696 1.76968C16.1014 1.36443 16.1014 0.709192 15.696 0.303939C15.2907 -0.101313 14.6355 -0.101313 14.2302 0.303939L7.99993 6.5342L1.76983 0.303939C1.36439 -0.101313 0.709337 -0.101313 0.304083 0.303939C-0.101361 0.709192 -0.101361 1.36443 0.304083 1.76968L6.53418 7.99994L0.304083 14.2302C-0.101361 14.6355 -0.101361 15.2907 0.304083 15.6959C0.506046 15.8981 0.771596 15.9996 1.03696 15.9996C1.30232 15.9996 1.56768 15.8981 1.76983 15.6959L7.99993 9.46569L14.2302 15.6959C14.4324 15.8981 14.6977 15.9996 14.9631 15.9996C15.2284 15.9996 15.4938 15.8981 15.696 15.6959C16.1014 15.2907 16.1014 14.6355 15.696 14.2302L9.46586 7.99994Z'
        fill='#1E1E20'
      />
    </svg>
  );
};
