import React from 'react';

export const Back = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2032_20848)'>
        <path
          d='M0.860697 8.47139L5.52736 13.1381C5.6531 13.2595 5.8215 13.3267 5.9963 13.3252C6.1711 13.3237 6.3383 13.2535 6.46191 13.1299C6.58552 13.0063 6.65563 12.8391 6.65715 12.6643C6.65867 12.4895 6.59147 12.3211 6.47003 12.1954L2.94136 8.66672L14.6654 8.66672C14.8422 8.66672 15.0117 8.59648 15.1368 8.47146C15.2618 8.34643 15.332 8.17687 15.332 8.00005C15.332 7.82324 15.2618 7.65367 15.1368 7.52865C15.0117 7.40362 14.8422 7.33339 14.6654 7.33339L2.94136 7.33339L6.47003 3.80472C6.53371 3.74322 6.58449 3.66966 6.61943 3.58832C6.65437 3.50699 6.67276 3.41951 6.67353 3.33099C6.6743 3.24247 6.65743 3.15468 6.62391 3.07275C6.59039 2.99082 6.54089 2.91638 6.4783 2.85379C6.4157 2.7912 6.34127 2.74169 6.25933 2.70817C6.1774 2.67465 6.08962 2.65778 6.0011 2.65855C5.91258 2.65932 5.8251 2.67771 5.74376 2.71265C5.66243 2.74759 5.58886 2.79838 5.52737 2.86205L0.860697 7.52872C0.735716 7.65374 0.665507 7.82328 0.665507 8.00005C0.665507 8.17683 0.735716 8.34637 0.860697 8.47139Z'
          fill='#1E1E20'
        />
      </g>
      <defs>
        <clipPath id='clip0_2032_20848'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(16 16) rotate(-180)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
