import { createContext } from 'react';
import {
  TWebsocketMessageContext,
  TActionsWebsocketMessageContext,
} from './types';

export const WebsocketMessageContext = createContext<TWebsocketMessageContext>({
  message: null,
});

export const ActionsWebsocketMessageContext =
  createContext<TActionsWebsocketMessageContext>({
    setMessage: () => null,
  });
