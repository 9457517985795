import React from 'react';

interface IProps {
  closeModal(): void;
}

export const Cross = ({ closeModal }: IProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={closeModal}
    >
      <path
        d='M13.4658 11.9999L19.6959 5.76968C20.1014 5.36443 20.1014 4.70919 19.6959 4.30394C19.2907 3.89869 18.6354 3.89869 18.2302 4.30394L11.9999 10.5342L5.76983 4.30394C5.36438 3.89869 4.70934 3.89869 4.30408 4.30394C3.89864 4.70919 3.89864 5.36443 4.30408 5.76968L10.5342 11.9999L4.30408 18.2302C3.89864 18.6355 3.89864 19.2907 4.30408 19.6959C4.50604 19.8981 4.77159 19.9997 5.03695 19.9997C5.30231 19.9997 5.56767 19.8981 5.76983 19.6959L11.9999 13.4657L18.2302 19.6959C18.4323 19.8981 18.6977 19.9997 18.963 19.9997C19.2284 19.9997 19.4938 19.8981 19.6959 19.6959C20.1014 19.2907 20.1014 18.6355 19.6959 18.2302L13.4658 11.9999Z'
        fill='#9AA1B0'
      />
    </svg>
  );
};
